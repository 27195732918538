<script lang="ts" context="module">
    export type Size = "sm" | "md" | "lg" | "xl"
    const sizeMap: { [size in Size]: string } = {
        sm: "h-[32px] w-[32px]",
        md: "h-[40px] w-[40px]",
        lg: "h-[44px] w-[44px]",
        xl: "h-[48px] w-[48px]",
    }

    export type IconSize = "sm" | "md" | "lg" | "xl"
    const iconSizeMap: { [size in IconSize]: string } = {
        sm: "h-[18px] w-[18px]",
        md: "h-6 w-6",
        lg: "h-8 w-8",
        xl: "h-10 w-10",
    }
</script>

<script lang="ts">
    import { Icon } from "svelte-awesome"
    import Button, { type Color } from "./Button.svelte"
    import type { IconType } from "svelte-awesome/components/Icon.svelte"

    let className = ""
    export { className as class }

    export let color: Color
    export let colorHover: Color | null = null
    export let colorActive: Color | null = null

    export let icon: IconType

    export let size: Size = "md"
    export let iconSize: IconSize = "md"

    export let disabled = false
</script>

<Button
    {color}
    {colorHover}
    {colorActive}
    class="!min-h-0 !p-0 {sizeMap[size]} {className}"
    {disabled}
    on:click
>
    <Icon data={icon} class={iconSizeMap[iconSize]} />
</Button>
